import React from "react"
import ReactHtmlParser from "react-html-parser"

import "./mainheading.scss"
export default function MainHeading({
  children,
  subHeading,
  version = "v1",
  heroSection = false,
  headingTag = "h2",
  ...props
}) {
  var ReactElement = React.createElement(
    headingTag,
    props,
    ReactHtmlParser(children)
  )

  return (
    <>
      {subHeading && (
        <>
          {heroSection ? (
            <h1 className={`subheadingtop ${version}`}>
              {ReactHtmlParser(subHeading)}
            </h1>
          ) : (
            <h3 className={`subheadingtop ${version}`}>
              {ReactHtmlParser(subHeading)}
            </h3>
          )}
        </>
      )}
      <ReactElement.type {...ReactElement.props} />
      {/* <h2 {...props}>{ReactHtmlParser(children)}</h2> */}
    </>
  )
}
