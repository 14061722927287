import React from "react"
import "./headsubhead.scss"

export default function HeadSubhead({
  heading,
  version = "v1",
  subheading,
  ...props
}) {
  return (
    <div className={`headsubhead ${version}`} {...props}>
      <h2 dangerouslySetInnerHTML={{ __html: heading }}></h2>
      {subheading && <p dangerouslySetInnerHTML={{ __html: subheading }}></p>}
    </div>
  )
}
